
import axios from '@/utils/http';
export const myCardListApi = (params) => axios.request({
    url: '/card/api/card/myCardList',
    method: 'get',
    data: params
});


export const emailRegisterApi = (params) => axios.request({
    url: '/card/api/card/cardInfo',
    method: 'post',
    data: params
});


export const emailSignApi = (params) => axios.request({
    url: '/card/api/common/registerByEmail',
    method: 'post',
    data: params
});


export const emailLoginApi = (params) => axios.request({
    url: '/card/api/common/loginByEmail',
    method: 'post',
    data: params
});


// ## 获取虚拟卡卡段信息
export const availableCardListApi = (params) => axios.request({
    url: '/card/api/card/availableCardList',
    method: 'get',
    data: params
});


// ## 获取虚拟卡开卡费用相关信息
export const getFeeInfoApi = (params) => axios.request({
    url: '/card/api/card/getFeeInfo',
    method: 'get',
    data: params
});


// ## 开虚拟卡
export const buyVirtualCardApi = (params) => axios.request({
    url: '/card/api/card/buyVirtualCard',
    method: 'post',
    data: params
});


// ## 获取卡的详情
export const cardInfoApi = (params) => axios.request({
    url: '/card/api/card/cardInfo',
    method: 'post',
    data: params
});


// ## 获取卡交易记录
export const getTradeListApi = (params) => axios.request({
    url: '/card/api/card/getTradeList',
    method: 'post',
    data: params
});


// ## 获取卡的cvv
export const getCvvApi = (params) => axios.request({
    url: '/card/api/card/getCvv',
    method: 'post',
    data: params
});


// ## 冻结卡
export const suspendCardApi = (params) => axios.request({
    url: '/card/api/card/suspendCard',
    method: 'post',
    data: params
});


// ## 充卡
export const rechargeCardApi = (params) => axios.request({
    url: '/card/api/card/rechargeCard',
    method: 'post',
    data: params
});


// ## 
// export const Api = (params) => axios.request({
//     url: '/card/api/card/cardInfo',
//     method: 'get',
//     data: params
// });