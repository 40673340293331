import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      title: 'bbPay'
    },
    component: Home,
  },
  {
    path: "/login",
    name: "login",
    meta: {
      title: 'login-bbPay'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/login/index.vue"),
  },
  {
    path: "/sign",
    name: "sign",
    meta: {
      title: 'sign-bbPay'
    },
    component: () =>
      import(/* webpackChunkName: "sign" */ "@/views/sign/index.vue"),
  },
  {
    path: "/forgot",
    name: "forgot",
    meta: {
      title: 'forgot-bbPay'
    },
    component: () =>
      import(/* webpackChunkName: "forgot" */ "@/views/forgot/index.vue"),
  },
  {
    path: "/admin",
    name: "admin",
    meta: {
      title: 'admin-bbPay'
    },
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/views/admin/index.vue"),

    children: [
      {
        path: "/admin/dashboard",
        name: "dashboard",
        meta: {
          title: 'dashboard-bbPay'
        },
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "@/views/dashboard/index.vue"),
      },
      {
        path: "/admin/wallet",
        name: "wallet",
        meta: {
          title: 'wallet-bbPay'
        },
        component: () =>
          import(/* webpackChunkName: "wallet" */ "@/views/wallet/index.vue"),
        children: [
        ]
      },
      {
        path: "/admin/wallet/history",
        name: "history",
        meta: {
          title: 'history-bbPay'
        },
        component: () =>
          import(/* webpackChunkName: "wallet" */ "@/views/wallet/history.vue"),
      },
      {
        path: "/admin/wallet/withdraw",
        name: "withdraw",
        meta: {
          title: 'withdraw-bbPay'
        },
        component: () =>
          import(/* webpackChunkName: "wallet" */ "@/views/wallet/withdraw.vue"),
      },
      {
        path: "/admin/wallet/topup",
        name: "topup",
        meta: {
          title: 'topup-bbPay'
        },
        component: () =>
          import(/* webpackChunkName: "wallet" */ "@/views/wallet/topup.vue"),
      },
      {
        path: "/admin/cards",
        name: "cards",
        meta: {
          title: 'cards-bbPay'
        },
        component: () =>
          import(/* webpackChunkName: "cards" */ "@/views/cards/index.vue"),
      },
      {
        path: "/admin/cardsDetail",
        name: "cardsDetail",
        meta: {
          title: 'cardsDetail-bbPay'
        },
        component: () =>
          import(/* webpackChunkName: "cardsDetail" */ "@/views/cards/detail.vue"),
        children: [
        ]
      },
      {
        path: "/admin/cardsTopup",
        name: "cardsTopup",
        meta: {
          title: 'cardsTopup-bbPay'
        },
        component: () =>
          import(/* webpackChunkName: "cardsTopup" */ "@/views/cards/topup.vue"),
        children: [
        ]
      },
      //sub menu的情况
      {
        path: '/admin/shop',
        name: 'cardShop',
        icon: 'menu',
        children: [
          {
            path: "/admin/shop/virtual",
            name: "shopVirtual",
            meta: {
              title: 'shopVirtual-bbPay'
            },
            component: () =>
              import(/* webpackChunkName: "shop" */ "@/views/shop/virtual.vue"),
          },
          {
            path: "/admin/shop/virtualDetail:itemInfo",
            name: "virtualDetail",
            meta: {
              title: 'virtualDetail-bbPay'
            },
            component: () =>
              import(/* webpackChunkName: "virtualDetail" */ "@/views/shop/virtualDetail.vue"),
            children: [
            ]
          },
          {
            path: "/admin/shop/physical",
            name: "shopPhysical",
            meta: {
              title: 'shopPhysical-bbPay'
            },
            component: () =>
              import(/* webpackChunkName: "shop" */ "@/views/shop/physical.vue"),
          },
          {
            path: "/admin/shop/physicalDetail",
            name: "physicalDetail",
            meta: {
              title: 'physicalDetail-bbPay'
            },
            component: () =>
              import(/* webpackChunkName: "physicalDetail" */ "@/views/shop/physicalDetail.vue"),
            children: [
            ]
          },

        ]
      },
      //sub menu的情况
      {
        path: '/admin/my',
        name: 'my',
        icon: 'menu',
        children: [
          {
            path: "/admin/my/announcement",
            name: "announcement",
            meta: {
              title: 'announcement-bbPay'
            },
            component: () =>
              import(/* webpackChunkName: "my" */ "@/views/my/record.vue"),
          },
          {
            path: "/admin/my/record",
            name: "record",
            meta: {
              title: 'record-bbPay'
            },
            component: () =>
              import(/* webpackChunkName: "my" */ "@/views/my/announcement.vue"),
          },
        ]
      }

    ]
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
