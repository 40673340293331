<template>
	<div class="footer">
		<div class="left">
			<img src="../../assets/home/logo.png" alt="" class="logo" />
			<div class="gs">
				{{ $t('footer.gs') }}
			</div>
		</div>
		<div class="right">
			<div class="one">
				<span>
					{{ $t('footer.one_title') }}
				</span>
				<a href="" target="_blank">
					{{ $t('footer.one_content') }}
				</a>
			</div>
			<div class="two">
				<span> {{ $t('footer.two_title') }}</span>
				<a href="" target="_blank">
					<img src="../../assets/footer/tt.png" alt="" />
				</a>
			</div>
		</div>
	</div>
</template>

<script setup>
// import { ref, reactive, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const router = useRouter();
const go = (type, url) => {
	if (type == 1) {
		window.open(url, '_blank');
	} else {
		router.push(url);
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.footer {
	width: 100%;
	height: 150px;
	color: #ffffff;
	background: #0D0D1F;
	padding: 0 150px;
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #f0f0f0;
}

/* 当前屏幕大于768px 应用媒体查询里面的样式 */
@media screen and (min-width: 768px) {
	.left {
		display: flex;
		align-items: center;
		padding-top: 8px;
		.logo {
			width: 138px;
			margin-right: 124px;
		}
		.gs {
			font-size: 22px;
		}
	}
	.right {
		display: flex;
		align-items: center;
		.one {
			display: flex;
			align-items: center;
			margin-right: 88px;
			span {
				margin-right: 25px;
			}
			a {
				color: #ffffff;
			}
		}
		.two {
			display: flex;
			align-items: center;
			span {
				margin-right: 25px;
			}
			a {
				height: 37px;
			}
			img {
				width: 37px;
			}
		}
	}
}
</style>
