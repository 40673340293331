
import axios from '@/utils/http';
export const inviteList = (params) => axios.request({
    url: '/card/api/invite/info',
    method: 'get',
    data: params
});

export const buyHistory = (params) => axios.request({
    url: '/card/api/card/buyCardHistory',
    method: 'get',
    data: params
});