<template>
	<div class="main">
		<div class="banner_box">
			<div class="left_box">
				<div class="title">bbpay</div>
				<div class="font">
					{{ $t('home.font') }}
					<div v-if="locale == 'zh'"></div>
					{{ $t('home.font2') }}
				</div>
				<div class="button_box" @click="router.push('/admin/dashboard')">
					<div class="left_button">
						{{ $t('home.left_button') }}
					</div>
					<img class="right_button" src="../assets/home/right_button.png" alt="" />
				</div>
			</div>
			<img src="../assets/home/banner1.png" alt="" class="right_box" />
			<img src="../assets/home/banner2.png" alt="" class="banner2" />
		</div>
		<div class="item_box">
			<div class="item_title">{{ $t('home.item_title') }}</div>
			<div class="item_title2">{{ $t('home.item_title2') }}</div>
			<div class="item_body">
				<div class="item">
					<img src="../assets/home/item1.png" alt="" class="item_img" />
					<div class="content_box">
						<div class="c_b_title">{{ $t('home.c_b_title') }}</div>
						<div class="c_b_font">{{ $t('home.c_b_font') }}</div>
						<img class="c_b_img" src="../assets/home/item_right_icon.png" alt="" />
					</div>
				</div>
				<div class="item">
					<img src="../assets/home/item2.png" alt="" class="item_img" />
					<div class="content_box">
						<div class="c_b_title">{{ $t('home.c_b_title2') }}</div>
						<div class="c_b_font">{{ $t('home.c_b_font2') }}</div>
						<img class="c_b_img" src="../assets/home/item_right_icon.png" alt="" />
					</div>
				</div>
				<div class="item">
					<img src="../assets/home/item3.png" alt="" class="item_img" />
					<div class="content_box">
						<div class="c_b_title">{{ $t('home.c_b_title3') }}</div>
						<div class="c_b_font">{{ $t('home.c_b_font3') }}</div>
						<img class="c_b_img" src="../assets/home/item_right_icon.png" alt="" />
					</div>
				</div>
			</div>
		</div>
		<div class="apply">
			<div class="apply1">
				<div class="apply1_left">
					<div class="title">
						{{ $t('home.apply_title') }}<span>{{ $t('home.apply_title2') }}</span>
					</div>
					<div class="font" :class="locale == 'en' ? 'eng_font' : ''">
						{{ $t('home.apply_font') }}
					</div>
					<!-- <img src="../assets/home/apply1.png" alt="" class="apply1_img apply1_img1" /> -->
					<div class="apply1_item apply1_item1">
						<img src="../assets/home/apply1_left_icon.png" alt="" />
						{{ $t('home.apply1_item1') }}
					</div>
					<div class="button_box">
						<!-- <img src="../assets/home/apply1.png" alt="" class="apply1_img" /> -->

						<div class="apply1_item apply1_item2">
							<img src="../assets/home/apply1_left_icon.png" alt="" />
							{{ $t('home.apply1_item2') }}
						</div>
						<div class="button" @click="router.push('/admin/shop/virtual')">
							{{ $t('home.apply_button') }}
							<img src="../assets/home/button_img.png" alt="" class="button_img" />
						</div>
					</div>
				</div>
				<img src="../assets/home/apply_right.png" alt="" class="apply1_right" />
			</div>
			<div class="apply2">
				<div class="apply2_left" :class="locale == 'en' ? 'eng_apply2_left' : ''">
					{{ $t('home.apply2_left') }}
				</div>
				<div class="apply2_right">”</div>
			</div>
			<div class="apply3" :class="locale == 'en' ? 'apply3_eng' : ''">
				<div class="one">
					<img src="../assets/home/apply3_icon1.png" class="one_img" alt="" />
					{{ $t('home.apply3_font') }}
				</div>
				<div class="two">
					<img src="../assets/home/apply3_icon2.png" class="two_img" alt="" />
					{{ $t('home.apply3_font2') }}
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, reactive } from 'vue';
import { CountTo } from 'vue3-count-to';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

import { Document, Menu as IconMenu, Location, Setting } from '@element-plus/icons-vue';

const { locale } = useI18n();
const router = useRouter();
const handleOpen = (key, keyPath) => {
	console.log(key, keyPath);
};
const handleClose = (key, keyPath) => {
	console.log(key, keyPath);
};
</script>

<style scoped lang="scss">
/* 当前屏幕大于768px 应用媒体查询里面的样式 */
@media screen and (min-width: 768px) {
	.main {
		width: 100%;
		height: calc(100vh - 80px);
		overflow: auto;
		// padding-top: 80px;
		background: #f6f8fc;
		.banner_box {
			width: 100%;
			height: 688px;
			background: #0d0d1f;
			border-radius: 0px 0px 0px 80px;
			display: flex;
			justify-content: space-between;
			padding: 0 160px;
			position: relative;
			.left_box {
				.title {
					font-size: 80px;
					color: #ffffff;
					font-weight: 600;
					margin-top: 88px;
				}
				.font {
					font-size: 53px;
					color: #f0f0f0;
					margin-top: 46px;
				}
				.button_box {
					display: flex;
					align-items: center;
					margin-top: 104px;
					width: 268px;
					height: 80px;
					background: #ffffff;
					border-radius: 11px;
					padding: 4px 6px;
					cursor: pointer;
					.left_button {
						width: 200px;
						height: 75px;
						background: #19d079;
						border-radius: 11px;
						font-size: 29px;
						color: #0d0d1f;
						display: flex;
						align-items: center;
						justify-content: center;
						// border: 2px solid #19d079;
					}
					.right_button {
						width: 35px;
						height: 35px;
						margin-left: 12px;
						// display: flex;
						// align-items: center;
						// justify-content: center;
						// img {
						// }
					}
				}
			}
			.right_box {
				margin: 37px 0 0 0;
				width: 525px;
				height: 652px;
			}
			.banner2 {
				width: 325px;
				height: 325px;
				position: absolute;
				left: 362px;
				top: 120px;
			}
		}
		.item_box {
			width: 100%;
			padding: 186px 160px 0;
			align-items: center;
			justify-content: center;
			text-align: center;
			.item_title {
				font-weight: 600;
				font-size: 67px;
				color: #333333;
				margin-bottom: 44px;
			}
			.item_title2 {
				font-size: 35px;
				color: #4a4a4a;
				margin-bottom: 76px;
			}
			.item_body {
				display: flex;
				justify-content: space-between;
				width: 100%;
				.item:hover {
					border: 1px solid #19d079;
				}
				.item {
					width: 507px;
					height: 575px;
					padding: 38px 26px;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					background: #ffffff;
					border-radius: 27px;
					.item_img {
						width: 333px;
						height: 280px;
					}
					.content_box {
						padding: 26px 36px;
						width: 453px;
						height: 224px;
						margin-top: 16px;
						display: flex;
						flex-direction: column;
						background: #f6f8fc;
						text-align: left;
						border-radius: 21px;
						.c_b_title {
							font-weight: 600;
							font-size: 34px;
							color: #333333;
						}
						.c_b_font {
							font-size: 24px;
							color: #4a4a4a;
							margin: 13px 0 17px;
						}
						.c_b_img {
							width: 48px;
							height: 40px;
						}
					}
				}
			}
		}
		.apply {
			width: 100%;
			margin-top: 160px;
			padding-bottom: 132px;
			.apply1 {
				width: 100%;
				display: flex;
				justify-content: space-between;
				.apply1_left {
					margin-left: 55px;
					padding: 50px 50px 40px 100px;
					width: 1130px;
					height: 591px;
					background: #ffffff;
					border-radius: 40px;
					.title {
						font-weight: normal;
						font-size: 62px;
						color: #333333;
						margin-bottom: 37px;
						span {
							color: #19d079;
							// text-decoration: underline;
						}
					}
					.font {
						width: 100%;
						// height: 93px;
						font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
						font-weight: normal;
						font-size: 32px;
						color: #333333;
					}
					.eng_font {
						font-size: 28px;
						font-size: 26px;
					}
					.apply1_item {
						width: 600px;
						height: 114px;
						background: #f6f8fc;
						border-radius: 12px;
						margin-right: 27px;
						display: flex;
						align-items: center;
						padding-left: 38px;
						margin-top: 37px;
						font-size: 36px;
						img {
							width: 45px;
							height: 45px;
							margin-right: 20px;
						}
					}
					.apply1_item2 {
						// margin: 40px 0 21px 0;
					}
					.button_box {
						width: 100%;
						display: flex;
						align-items: flex-end;
						.button {
							display: flex;
							align-items: center;
							justify-content: center;
							width: 324px;
							height: 114px;
							background: #333333;
							border-radius: 8px;
							font-size: 36px;
							color: #ffffff;
							font-weight: 600;
							.button_img {
								margin-left: 19px;
								width: 50px;
								height: 50px;
							}
						}
					}
				}
				.apply1_right {
					margin-right: 55px;
					width: 585px;
					height: 591px;
				}
			}
			.apply2 {
				margin: 20px 0 28px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 0 160px;
				.apply2_left {
					width: 667px;
					height: 160px;
					font-size: 64px;
					color: #333333;
					background: url('../assets/home/apply2_left.png');
					background-size: 667px 160px;
					padding: 24px 0 0 24px;
				}
				.eng_apply2_left {
					padding: 38px 0 0 12px;
					font-size: 50px;
				}
				.apply2_right {
					font-family: Silom;
					font-size: 187px;
					color: #19d079;
					line-height: 285px;
				}
			}
			.apply3 {
				width: 1600px;
				height: 374px;
				background: #ffffff;
				border-radius: 27px;
				margin: 0 auto;
				padding-top: 82px;
				font-size: 52px;
				color: #333333;
				.one {
					margin-left: 87px;
					display: flex;
					align-items: center;
					img {
						width: 80px;
						height: 80px;
						margin-right: 27px;
					}
				}
				.two {
					padding-top: 66px;
					margin-left: 300px;
					display: flex;
					align-items: center;
					img {
						width: 80px;
						height: 80px;
						margin-right: 27px;
					}
				}
			}
			.apply3_eng {
				font-size: 30px;
			}
		}
		// .left {
		// 	width: 320px;
		// 	height: calc(100vh - 80px);
		// 	padding: 35px 5px 0 30px;
		// 	background: #ffffff;
		// 	font-size: 18px;
		// 	color: #666666;
		// 	font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
		// 	.el-menu {
		// 		height: 100%;
		// 		border-right: 0;
		// 	}
		// 	ul {
		// 		padding-left: 0 !important;
		// 		.el-menu-item {
		// 			padding: 0;
		// 		}
		// 		.is-active {
		// 			// color: #ffffff;
		// 			// background: #19d079;
		// 			border-radius: 6px;
		// 		}
		// 	}
		// }
	}
}
@media screen and (max-width: 768px) {
	.home {
	}
}
</style>

<style lang="scss">
.el-sub-menu__title {
	padding-left: 0 !important;
	// .el-menu .el-menu--inline {
	// 	padding-left: 0 !important;
	// }
}
.el-menu {
	ul {
		padding-left: 16px !important;
	}
}
</style>
