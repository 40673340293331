
import axios from '@/utils/http';

// ## 获取用户信息
export const userInfoApi = (params) => axios.request({
    url: '/card/api/user/userInfo',
    method: 'get',
    data: params
});


// ## 获取用户地址
export const getAddressApi = (params) => axios.request({
    url: '/card/api/user/getAddress',
    method: 'get',
    data: params
});


// ## 获取提现费率 / 最小提现数量
export const getWithdrawConfigApi = (params) => axios.request({
    url: '/card/api/wallet/getWithdrawConfig',
    method: 'get',
    data: params
});


// ## 提现
export const withdrawApi = (params) => axios.request({
    url: '/card/api/wallet/withdraw',
    method: 'post',
    data: params
});


// ## 获取用户提现列表  提现记录
export const withdrawListApi = (params) => axios.request({
    url: '/card/api/wallet/withdrawList',
    method: 'get',
    data: params
});



// ## 获取余额变动记录 历史记录
export const balanceChangeListApi = (params) => axios.request({
    url: '/card/api/wallet/balanceChangeList',
    method: 'get',
    data: params
});


// ## 获取充值记录
export const rechargeListApi = (params) => axios.request({
    url: '/card/api/wallet/rechargeList',
    method: 'get',
    data: params
});