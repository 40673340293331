<template>
	<div class="navBox">
		<!-- pc 端布局 -->
		<div class="nav">
			<div class="body">
				<div class="left_box">
					<div class="left">
						<img src="../../assets/home/logo.png" alt="" />
					</div>
					<div class="center" v-if="navFlag == true">
						<div
							class="item"
							v-for="(item, index) in tabList"
							:key="index"
							:class="{ active: item.active }"
							@click="toUrl(item)"
						>
							<span> {{ item.name }}</span>
							<div class="bottom_box"></div>
						</div>
					</div>
				</div>
				<div class="right">
					<div class="right_in18" @click.stop="in18Flag = !in18Flag">
						<img :src="localeImg" alt="" />
						{{ localeNmae }}
						<img class="right_in18_icon" src="@/assets/nav/xia.png" alt="" />
					</div>
					<div v-if="!loginFlag" style="display: flex">
						<div class="button" @click="login('/sign')">
							{{ $t('tabList.register') }}
						</div>
						<div class="button back" @click="login('/login')">
							{{ $t('tabList.login') }}
						</div>
					</div>
					<div class="right_in18" v-else @click.stop="txFlag = !txFlag">
						<img class="tx" src="../../assets/nav/tx.webp" alt="" />
						{{ userData.email }}
						<img class="right_in18_icon" src="@/assets/nav/xia.png" alt="" />
					</div>
				</div>
			</div>
			<div
				class="in18box"
				v-show="in18Flag"
				:style="{
					right: loginFlag ? '24%' : '15%',
				}"
			>
				<div
					class="item"
					v-for="(item, index) in in18List"
					:key="index"
					:class="{ item_active: !item.active }"
					@click="setIn18(item)"
				>
					{{ item.name }}
				</div>
			</div>
			<div class="in18box" v-show="txFlag">
				<div class="item" @click="outLogin">
					{{ $t('nav.button') }}
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, reactive, computed, onMounted, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { userInfoApi } from '@/api/index';

const { t, locale } = useI18n();
const router = useRouter();
const route = useRoute();
let navFlag = ref(true);
let in18Flag = ref(false);
let txFlag = ref(false);
let childrenFlag = ref(false);
let localeNmae = ref('');
let localeImg = ref('');

let tabList = reactive([
	{
		name: t('tabList.list1'),
		key: 1,
		active: true,
		url: '/',
	},
	{
		name: t('tabList.list2'),
		key: 2,
		active: false,
		url: '/admin/dashboard',
	},
	// {
	// 	name: t('tabList.list3'),
	// 	key: 3,
	// 	active: false,
	// 	url: '/News',
	// },
]);
const in18List = reactive([
	{
		img: require('../../assets/nav/cn.svg'),
		name: '简体中文',
		id: 'zh',
		key: 1,
		active: true,
	},
	{
		img: require('../../assets/nav/us.svg'),
		name: 'English',
		id: 'en',
		key: 2,
		active: false,
	},
	// {
	// 	name: '日本語',
	// 	id: 'jap',
	// 	key: 3,
	// 	active: false,
	// },
	// {
	// 	name: '한글',
	// 	id: 'kor',
	// 	key: 4,
	// 	active: false,
	// },
	// {
	// 	name: 'Deutsch',
	// 	id: 'ger',
	// 	key: 5,
	// 	active: false,
	// },
	// {
	// 	name: 'Tiếng Việt',
	// 	id: 'vie',
	// 	key: 6,
	// 	active: false,
	// },
]);
const loginFlag = ref(false);
loginFlag.value = localStorage.getItem('Token') ? true : false;
const userData = ref({});
userData.value.email = localStorage.getItem('email');
in18List.map((items) => {
	items.active = false;
	if (locale.value == items.id) {
		items.active = true;
		localeNmae.value = items.name;
		localeImg.value = items.img;
	}
});
const show = (type) => {
	let navs = document.getElementById('navs');
	if (type == 1) {
		navs.style.display = 'flex';
	} else {
		navs.style.display = 'none';
	}
};

const showIn18 = () => {
	in18Flag.value = true;
};

const hideIn18 = () => {
	in18Flag.value = false;
};

const showTx18 = () => {
	txFlag.value = true;
};

const hideTx18 = () => {
	txFlag.value = false;
};
const login = (type) => {
	router.push(type);
};
const outLogin = () => {
	localStorage.removeItem('Token');
	localStorage.removeItem('email');
	router.push('/login');
};
const toUrl = (item) => {
	tabList.map((items) => {
		if (item.name == items.name) {
			router.push(item.url);
		}
	});
};

const setIn18 = (item) => {
	in18List.map((items) => {
		items.active = false;
		if (item.name == items.name) {
			items.active = true;
			locale.value = item.id;
			localeNmae.value = item.name;
			localeImg.value = item.img;
			localStorage.setItem('currentLanage', item.id);
			window.location.reload();
		}
	});
	hideIn18();
};
watch(
	() => route.path,
	(item, oldPath) => {
		// in18List.map((items) => {
		// 	if (items.id == locale.value) {
		// 		items.active = true;
		// 		console.log(items)
		// 	}
		// });

		// let url = window.location.href;
		// if (url.lastIndexOf('inviteCode=')) {
		// 	let index = url.lastIndexOf('inviteCode=');
		// 	let inviteCode = url.substring(index + 11, url.length);

		// 	localStorage.setItem('inviteCode', inviteCode);
		// }
		if (oldPath == '/login' && item == '/') {
			userData.value.email = localStorage.getItem('email');
		}
		loginFlag.value = localStorage.getItem('Token') ? true : false;
		if (loginFlag.value) {
			localStorage.removeItem('email');
		}
		tabList.map((items) => {
			items.active = false;
		});
		if (item == '/') {
			tabList[0].active = true;
		} else {
			tabList[1].active = true;
		}
	},
);
defineExpose({ hideIn18, hideTx18 });

onMounted(() => {
	// 检测元素是否在视图窗口的函数
	// const act = () => {
	// 	in18List.map((items) => {
	// 		if (locale.value == items.id) {
	// 			items.active = true;
	// 		} else {
	// 			items.active = false;
	// 		}
	// 	});
	// };
	// act();
	// setIn18(locale.value)
});
computed(() => {
	return locale.value;
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
/* 当前屏幕大于768px 应用媒体查询里面的样式 */
@media screen and (min-width: 768px) {
	.navBox {
		width: 100%;
		height: 90px;
		.navbar {
			display: none;
		}
	}
	.nav {
		width: 100%;
		height: 90px;
		color: #ffffff;
		position: fixed;
		top: 0;
		z-index: 999;
		background: #0d0d1f;
		display: flex;
		align-items: center;
		// padding: 30px 40px 26px;
		// position: relative;
		.body {
			box-sizing: border-box;
			// padding: 10px 40px;
			width: 100%;
			margin: 0 120px;
			// height: 34px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			border-bottom: 1px solid #716c6c;
			.left_box {
				display: flex;
				align-items: center;
				.left {
					width: 110px;
					display: flex;
					align-items: center;
					margin-right: 240px;
					img {
						width: 100%;
					}
				}
				.center {
					display: flex;
					.item {
						display: flex;
						margin-right: 80px;
						border-radius: 6px;
						font-size: 20px;
						font-weight: 500;
						display: flex;
						flex-direction: column;
						cursor: pointer;
						span {
							display: inline-block;
							margin-bottom: 12px;
						}
					}
					.item:hover {
						.bottom_box {
							width: 100%;
							min-width: 24px;
							height: 4px;
							background: #1ece47;
						}
					}
					.active {
						.bottom_box {
							width: 100%;
							min-width: 24px;
							height: 4px;
							background: #1ece47;
						}
					}
				}
			}
			.right {
				display: flex;
				.right_in18 {
					display: flex;
					align-items: center;
					img {
						margin: 0 12px;
						width: 30px;
						// height: 36px;
					}
					.tx {
						border-radius: 50%;
						width: 30px;
						height: 30px;
					}
					.right_in18_icon {
						margin: 0 24px 0 12px;
						width: 12px;
					}
				}
				.button {
					width: 76px;
					height: 36px;
					border: 1px solid #ffffff;
					border-radius: 6px;
					margin-left: 4px;
					line-height: 36px;
					text-align: center;
					font-size: 16px;
					font-weight: 600;
				}
				.back {
					background: #19d079;
					border: 0;
				}
			}
		}
		.box {
			position: absolute;
			width: 1050px;
			height: 378px;
			top: 80px;
			left: 435px;
			background: #262626;
			padding: 48px 58px 0 58px;
			text-align: left;
			display: flex;
			justify-content: space-between;
			box-sizing: border-box;
			// background: url('../../assets/nav/back.png');
			background-size: 1050px 378px;
			background-repeat: no-repeat;
			border-radius: 10px;
			.item {
				display: flex;
				width: 244px;
				flex-direction: column;
				align-items: flex-start;
				.item-top {
					text-align: center;
					margin-bottom: 30px;
					box-sizing: border-box;
					padding: 16px 32px;
					line-height: 22px;
					font-size: 22px;
					font-weight: 500;
					background: #8b8883;
					cursor: pointer;
					border: 3px solid rgba(139, 136, 131, 1);
					border-radius: 60px;
					color: rgba(255, 255, 255, 1);
					background: rgba(0, 0, 0, 0);
					cursor: pointer;
				}
				.item-top:hover {
					color: rgba(0, 0, 0, 1);
					background: #ffffff66;
					border: 3px solid transparent;
					background-clip: padding-box, border-box;
					background-origin: padding-box, border-box;
					background-image: linear-gradient(to right, #ffffff66, #ffffff66),
						linear-gradient(90deg, #ffffff, #99939300, #ffffff);
				}
				.item-body {
					width: auto;
					height: 42px;
					margin-top: 10px;
					display: flex;
					align-items: center;
					cursor: pointer;
					font-size: 18px;
					padding: 6px 9px;
					white-space: nowrap; //溢出不换行
					img {
						width: 30px;
						margin-right: 9px;
					}
				}
				.item-body:hover {
					border-radius: 30px;
					background: rgba(66, 66, 66, 1);
				}
			}
			.item3 {
				width: 340px;
			}
		}
		.in18box {
			position: absolute;
			width: 140px;
			min-height: 50px;
			// height: 100px;
			top: 90px;
			right: 7.6%;
			background: rgba(217, 217, 217, 0.6);
			padding: 10px 0 10px 8px;
			// text-align: left;
			border-radius: 0 0 10px 10px;
			.item {
				text-align: center;
				margin-bottom: 10px;
				width: 120px;
				height: 36px;
				line-height: 36px;
				border-radius: 10px;
				color: #000000;
				background: #ffffff;
				cursor: pointer;
			}
			.item_active {
				background: rgba(231, 231, 231, 1);
			}
		}
	}
}
/* max-width 最大宽度  当前屏幕小于992px会应用当前媒体查询里面的样式 */
@media screen and (max-width: 768px) {
}
</style>
