module.exports = {
    home: {
        menuList: {
            one: "Dashboard",
            two: "Wallet",
            three: "My Cards",
            four: "Card Shop",
            four_1: "Virtual Cards",
            four_2: "Physical Cards",
            five: "My",
            five_1: "Card Purchase History",
            five_2: "Announcement list",
        },
        font: "Complete global transactions with one card.",
        font2: "Manage multiple currencies unifiedly and enjoy a more efficient life.",
        left_button: "Start Now",
        item_title: "Why Choose bbpay",
        item_title2: "You will have the most powerful card BIN.",
        c_b_title: "Low Fees",
        c_b_font: "Save 90% of rate",
        c_b_title2: "Wide Range",
        c_b_font2: "Available in over 180 countries",
        c_b_title3: "Various Scenarios",
        c_b_font3: "Supports over 40 currencies for smooth consumption experience",
        // apply
        apply1_item1: "Virtual card/Physical card, easy to apply",
        apply1_item2: "Physical card, enjoy prompt delivery service",
        apply_title: "How to",
        apply_title2: "Apply for a Card?",
        apply2_left: "What Can We Do For You?",
        apply_font: "Apply for a card and enjoy our top-level cash-out service, which allows you to instantly convert/transfer cryptocurrencies into fiat currencies.",
        apply_button: "Apply Now",
        apply3_font: "High-efficiency liquidity, no need to exchange multiple currencies",
        apply3_font2: "World-leading security, no need to frequently switch between multiple platforms",
    },
    tabList: {
        list1: 'Home',
        list2: 'bbpaycard',
        login: "Login",
        register: "Register"
    },
    sign: {
        font1: "Trade Together, Share The Profits",
        font2: "You will have the most powerful card BIN",
        title: "Welcome to bbpay",
        submit: "Create account",
        goOther1: "Already have an account?",
        goOther2: "Log in now",
        send: "Send verification code",
    },
    login: {
        forgot: "Forgot password?",
        submit: "Log In",
        goOther1: "Don't have an account?",
        goOther2: "Create one",
        email: "Email",
        code: "Code",
        password: "Password",
        password2: "Password Confirm",

    },
    forgot: {
        submit: "Reset Password",
    },
    footer: {
        gs: "© 2025 bbpay Card. All rights reserved.",
        one_title: "Related Links:",
        one_content: "Legal Statement",
        two_title: "Contact Us:",
        // two_content: "",
    },
    wallet: {
        title: "My Wallet",
        name: "Balance",
        w_item1: "Deposit",
        w_item2: "Withdraw",
        h_title: "Transaction History",
        h_title2: "View All Records",
        empty: "No Data",
        state1: "Deposit",
        state2: "Withdrawal",
        state3: "Open card",
        state4: "Charge card",
        state5: "Open card rebates",
        state6: "Charge card rebates",

    },
    wallet_history: {
        breadcrumb1: "Back",
        breadcrumb2: "Wallet",
        breadcrumb3: "View All Transaction History",
        title: "Transaction History",
        title1: "Deposit Records",
        title2: "Withdrawal Records",
        empty: "No Data",
        table1: "Status",
        status1: "Pending",
        status2: "Already passed",
        status3: "Declined",
        status4: "completed",
        status5: "Failed",
        table2: "Amount",
        table4: "Address",
        table5: "Transaction Fee",
        table6: "Creation Time"
    },
    wallet_topup: {
        breadcrumb1: "Back",
        breadcrumb2: "Wallet",
        breadcrumb3: "Deposit",
        label1: "Please send",
        label2: "to this address",
        warning1: "If the transferred amount is inconsistent with the order amount below, it will result in failure to credit the account.",
        warning2: "1.Transfer the amount including the decimal part if any.",
        warning3: "2.Some exchanges will deduct a 1USDT network fee for withdrawal. Please ensure that the withdrawal amount and the order amount are the same.",
    },
    wallet_withdraw: {
        breadcrumb1: "Back",
        breadcrumb2: "Wallet",
        breadcrumb3: "Withdraw",
        name: "Withdrawable Balance",
        name2: "View Withdrawal Records",
        label1: "Payment Method",
        label2: "Recipient Address",
        label3: "Withdrawal Amount",
        money1: "For service charge",
        money2: "Minimum withdrawal",
        money3: "and it will take 1-3 working days for the funds to arrive.",
        button: "Withdraw"
    },
    card: {
        title: "My Cards",
        empty: "No records, apply for a card.",
        empty_button: "Apply Now",
        button: "View Card",
    },
    card_detail: {
        breadcrumb1: "Back",
        breadcrumb2: "Wallet",
        breadcrumb3: "View Card",
        font: "Balance",
        button1: "Card Recharge",
        button2: "View CVV",
        button3: "Freeze Card",
        h_title: "Transaction Records",
        empty: "No Data",
        success: "Success",
        dia_title: "Freeze Card",
        dia_font1: "Are you sure that the frozen card number is:",
        dia_font2: "The card?",
        confirm: "Confirm",
        cancel: "Cancel"
    },
    card_topup: {
        breadcrumb1: "Back",
        breadcrumb2: "Wallet",
        breadcrumb3: "View Card",
        name: "Available Balance",
        label: "currency",
        label2: "money",
        tips1: "You will deduct:",
        tips2: "You will receive:",
        tips3: "Top-up fee:",
        button: "Top up immediately",
    },
    card_shop_v: {
        title: "Card Store-Virtual",
        button: "Apply Now",
        fee1: "Top-up fee",
        fee2: "First charge required: Yes",
    },
    card_shop_v_detail: {
        breadcrumb1: "Back",
        breadcrumb2: "Virtual",
        breadcrumb3: "Apply for Virtual Card",
        title: "Card Store Virtual",
        button: "Apply Now",
        fee1: "Top-up fee",
        fee2: "First charge required: Yes",
        number: "Apply",
        item1: "Quantity:",
        item2: "Amount:",
        item3: "Minimum initial deposit:",
        item4: "Total Payment:",
        button: "Purchase Card",
        title: "Instructions",
        c_title1: "Supported Scenarios",
        c_body1_1: "Adobe, Aliexpress, Amazon, AWS, ChatGPT, CloudFlare，Facebook, GODADDY,MidJourney, Notion, Telegram, Tiktok, Walmart, Wechat/ AliPay, MasterCard，PayPal, Grab, ONLYFANS, Patreon, Etsy, Apple, Uber.",
        c_body1_2: "(Not currently supporting US Bank; Binance, OKX and other virtual currency platforms for consumption transactions, otherwise the card will be immediately cancelled and frozen)",
        c_title2: "Charge",
        c_body2_1: "Top-up fee:",
        c_body2_2: "Card purchase fee:",
        c_title3: "Quota",
        c_body3_1: "Minimum deposit:",
        c_body3_2: "Maximum deposit:",
        c_body3_3: "Maximum purchase:",
        c_body3_4: "Is initial deposit required:Yes",
        c_body3_5: "Minimum initial deposit:",
        c_title4: "Notice",
        c_body4_1: "If the card-issuing party detects malicious behaviors such as a large number of refunds, cancellations, failures, or chargebacks during the use process, the card will be automatically frozen, and a fee of 10 USD will be deducted each time.",
        c_body4_2: "If the overall transaction failure rate exceeds 20%, the card will be automatically frozen.",
        c_body4_3: "If the card balance is insufficient and there are 3 consecutive authorization failures in total, the card will be automatically cancelled.",
        c_body4_4: "If the card is frozen, please contact customer service for help.",
        c_body4_5: "he card validity period is 3 years.",
        api: "For customers whose monthly transaction volume exceeds 500,000 USD or who need API integration, please contact:"
    },
    my_buy_history: {
        title: "Card Purchase Records",
        label1: "Card Paragraph,Quantity",
        label2: "Order Amount",
        label3: "Transaction Date"
    },
    announcement: {
        title: "Announcement"
    },
    dashboard: {
        title: "My Invitations",
        name1: "My Commission Rate",
        body1_1: "for card purchase",
        body1_2: "for deposit",
        name2: "Total Commission",
        name3: "Total Number of Invitees",
        name4: "Total Number of Invitees who Hold Card",
        left_title1: "Earn money with your friends",
        left_title2: "Share high commissions from the transactions of your invitees",
        right_title1: "My referral code",
        right_title2: "My recommended Url",
        button: "Invite Now",
        history: "Referral History",
        empty: "No Data",
        history2: "Reward History",
        dia: "My referral QR code",
        message1: "Copy successfully, share it with your friends!",
        message2: "Copy failed, share it with your friends!"
    },
    nav: {
        button: "Log out"
    }
}