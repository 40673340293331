
import axios from '@/utils/http';

// ## 发送验证码
export const emailRegisterApi = (params) => axios.request({
    url: '/card/api/common/sendEmail',
    method: 'post',
    data: params
});


// ## 邮箱注册
export const emailSignApi = (params) => axios.request({
    url: '/card/api/common/registerByEmail',
    method: 'post',
    data: params
});


// ## 邮箱登录
export const emailLoginApi = (params) => axios.request({
    url: '/card/api/common/loginByEmail',
    method: 'post',
    data: params
});


// ## 邮箱登录
export const resetPasswordApi = (params) => axios.request({
    url: '/card/api/common/resetPassword',
    method: 'post',
    data: params
});