module.exports = {
  home: {
    menuList: {
      one: "我的邀请",
      two: "钱包",
      three: "我的卡片",
      four: "卡片商店",
      four_1: "虚拟卡",
      four_2: "实体卡",
      five: "我的",
      five_1: "卡片购买历史",
      five_2: "公告列表",
    },
    font: "全球消费一卡完成",
    font2: "多重货币，统一管理，享受更高效的生活",
    left_button: "立即启动",
    item_title: "为什么选择bappy",
    item_title2: "你将拥有最强大和最有力的卡片BIN。",
    c_b_title: "费率低",
    c_b_font: "节省90%的资金费率",
    c_b_title2: "范围广",
    c_b_font2: "180+国家通用",
    c_b_title3: "场景全",
    c_b_font3: "40+种支持的货币，消费丝滑体验",
    // apply
    apply1_item1: "虚拟卡/实体卡，一键申请",
    apply1_item2: "实体卡，享受及时送达服务",
    apply_title: "如何",
    apply_title2: "申请卡片？",
    apply2_left: "我们能为你带来什么？",
    apply_font: "申请卡片，享受我们顶级的出金服务，可即时将加密货币兑换/转账为法币",
    apply_button: "立即申请",
    apply3_font: "高效的资金流动性，无需兑换多国货币",
    apply3_font2: "全球顶尖的资金安全性，无需多平台频繁切换",
  },
  sign: {
    font1: "一起交易，共享收益",
    font2: "你将拥有最强大和最有力的卡片BIN",
    title: "欢迎来到bbpay",
    submit: "创建账户",
    goOther1: "已经有账户，",
    goOther2: "立即登录",
    send: "发送验证码",
  },
  login: {
    forgot: "忘记密码？",
    submit: "登录账户",
    goOther1: "没有账户，",
    goOther2: "去创建",
    email: "邮箱",
    code: "验证码",
    password: "密码",
    password2: "确认密码",
  },
  forgot: {
    submit: "重置密码",
  },
  tabList: {
    list1: '首页',
    list2: 'bbpaycard',
    login: "登录",
    register: "注册"
  },
  footer: {
    gs: "© 2025 bbpay Card, 公司版权所有.",
    one_title: "相关链接：",
    one_content: "法律声明",
    two_title: "联系方式：",
    // two_content: "",
  },
  wallet: {
    title: "我的钱包",
    name: "账户余额",
    w_item1: "充值",
    w_item2: "提现",
    h_title: "历史记录",
    h_title2: "查看全部记录",
    empty: "暂无数据",
    state1: "充值",
    state2: "提现",
    state3: "开卡",
    state4: "充卡",
    state5: "开卡返利",
    state6: "充卡返利",
  },
  wallet_history: {
    breadcrumb1: "返回",
    breadcrumb2: "钱包",
    breadcrumb3: "查看全部历史记录",
    title: "历史记录",
    title1: "充值记录",
    title2: "提现记录",
    empty: "暂无数据",
    table1: "状态",
    status1: "待审批",
    status2: "已通过",
    status3: "已拒绝",
    status4: "已完成",
    status5: "已失败",
    table2: "金额",
    table4: "地址",
    table5: "手续费",
    table6: "创建时间",
  },
  wallet_topup: {
    breadcrumb1: "返回",
    breadcrumb2: "钱包",
    breadcrumb3: "充值",
    label1: "请发送",
    label2: "到这个地址",
    warning1: "转入金额如果和下方订单金额不一致，将导致无法入账。",
    warning2: "1.如小数点后有金额也要转入。",
    warning3: "2.部分交易所提现会扣除1USDT手续费,请务必确保转出金额和订单金额一致。",
  },
  wallet_withdraw: {
    breadcrumb1: "返回",
    breadcrumb2: "钱包",
    breadcrumb3: "提现",
    name: "账户余额",
    name2: "查看提现记录",
    label1: "支付方式",
    label2: "收款地址",
    label3: "提现金额",
    money1: "手续费",
    money2: "最少提现",
    money3: "到账需要1-3个工作日",
    button: "提现"
  },
  card: {
    title: "我的卡片",
    empty: "无记录，我要申请卡片",
    empty_button: "去申请",
    button: "查看卡片",
  },
  card_detail: {
    breadcrumb1: "返回",
    breadcrumb2: "我的卡片",
    breadcrumb3: "查看卡片",
    font: "卡片余额",
    button1: "卡片充值",
    button2: "查看CVV",
    button3: "冻结卡片",
    h_title: "交易记录",
    empty: "暂无数据",
    success: "成功",
    dia_title: "冻结卡片",
    dia_font1: "您确定冻结卡号为：",
    dia_font2: "卡片嘛？",
    confirm: "确认",
    cancel: "取消"
  },
  card_topup: {
    breadcrumb1: "返回",
    breadcrumb2: "我的卡片",
    breadcrumb3: "充值卡片",
    name: "可用余额",
    label: "货币",
    label2: "金额",
    tips1: "您将扣除：",
    tips2: "您将收到：",
    tips3: "充值手续费：",
    button: "立即充值",
  },
  card_shop_v: {
    title: "卡片商店-虚拟卡",
    button: "立即申请",
    fee1: "充值手续费",
    fee2: "需要首充：是",
  },
  card_shop_v_detail: {
    breadcrumb1: "返回",
    breadcrumb2: "虚拟卡",
    breadcrumb3: "申请虚拟卡",
    fee1: "充值手续费",
    fee2: "需要首充：是",
    number: "开启",
    item1: "购卡数量:",
    item2: "购卡金额:",
    item3: "首次最低充值:",
    item4: "总计支付:",
    button: "购买卡片",
    title: "购卡说明",
    c_title1: "支持消费场景",
    c_body1_1: "Adobe, Aliexpress, Amazon, AWS, ChatGPT, CloudFlare，Facebook, GODADDY,MidJourney, Notion, Telegram, Tiktok, Walmart, Wechat/ AliPay, MasterCard，PayPal, Grab, ONLYFANS, Patreon, Etsy, Apple, Uber.",
    c_body1_2: "(暂不支持US Bank;Binance, OKX等虚拟货币平台进行消费交易， 否则会立刻销卡并冻结)",
    c_title2: "手续费",
    c_body2_1: "充值手续费:",
    c_body2_2: "购卡费用:",
    c_title3: "额度说明",
    c_body3_1: "最低充值金额:",
    c_body3_2: "最高充值金额:",
    c_body3_3: "最大购卡数量:",
    c_body3_4: "是否需要首次充值:是",
    c_body3_5: "最小首次充值金额:",
    c_title4: "卡片使用须知",
    c_body4_1: "如果发卡方在使用过程中检测到大批量退款、取消、失败或退单等恶意行为，卡片将自动冻结，每次将扣除10USD的费用。",
    c_body4_2: "如果整体交易失败率超过20%，卡片将自动冻结。",
    c_body4_3: "如果卡片余额不足，并累计达成3次连续授权失败，卡片将自动注销。",
    c_body4_4: "如果卡片被冻结，请联系客服申请解冻。",
    c_body4_5: "卡片有效期为3年。",
    api: "对于月交易量超过50万USD或者需要API集成的客户可以联系邮箱："
  },
  my_buy_history: {
    title: "卡片购买记录",
    label1: "卡段,数量",
    label2: "订单金额",
    label3: "交易日期"
  },
  announcement: {
    title: "公告列表"
  },
  dashboard: {
    title: "我的邀请",
    name1: "我的佣金率",
    body1_1: "购卡",
    body1_2: "充值",
    name2: "总佣金",
    name3: "总邀请人数",
    name4: "总邀请人持卡数",
    left_title1: "与你的朋友一起赚钱",
    left_title2: "从您邀请人的交易中分享高额的佣金",
    right_title1: "我的推荐码",
    right_title2: "我的推荐链接",
    button: "立即生成二维码",
    history: "推荐历史",
    empty: "暂无数据",
    history2: "奖励历史",
    dia: "邀请码",
    message1: "复制成功，快分享给好友吧",
    message2: "复制失败！快联系管理员吧"
  },
  nav: {
    button: "登出"
  }
}